input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  opacity: 1;
  margin: 0;
}

* {
  outline: none;
}

a {
  color: black;
}

.product-cart {
  display: flex;
  box-sizing: border-box;
  padding-bottom: 20px;
}

.image,
.image-link div {
  position: relative;
}

.image {
  overflow: hidden;
}

.image-link div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-list .product-cart {
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #989898;
}
.product-list .product-cart .image {
  width: 250px;
  height: auto;
  aspect-ratio: 1 / 1;
}
.product-list .product-cart .image a div {
  height: 100%;
}
.product-cart .image a div img {
  aspect-ratio: 1 / 1;
  width: 100%;
  height: 100%;
}
.image-link div > .second-img {
  position: absolute;

  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.icon-zoom {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-size: 14px;
  visibility: hidden;
}

.icon-zoom button,
.icon-zoom button:hover {
  background-color: #ff0088 !important;
  color: white;
  border-radius: 50%;
}

@media (max-width: 991px) {
  .icon-zoom {
    display: none !important;
  }
}

@media (max-width: 771px) {
  .product-cart {
    flex-direction: column;
  }
  .product-list img {
    width: 100%;
  }
  .product-list .product-cart .image {
    width: 100%;
  }
}

.caption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  gap: 20px;
  padding: 0 5px;
}
.product-list .caption {
  width: 50%;
}
.description {
  font-size: 14px;
  color: #656565;
}

.price-new {
  font-weight: 600;
  font-size: 18px;
}

.price-tax {
  font-size: 14px;
  color: #989898;
}

.cart-btn,
.step,
.group-btn {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 5px;
  -webkit-user-select: none; /* Safari */
  cursor: pointer;
}

.step {
  width: 50px;
  height: 40px;
  border: 1px solid rgb(201, 201, 201);
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.step {
  background-color: white;
}
.step input,
.step span {
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
}

.step span {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.step span svg:first-child {
  border-bottom: 1px solid #dadada;
}

.step span svg {
  background-color: #eeeeee;
  flex-grow: 1;
  cursor: pointer;
  border-left: 1px solid #dadada;
}

.step span svg:active {
  box-shadow: inset 0px 0px 3px 1px #9f8787;
}

.addcart-btn {
  height: 100%;
  background: #ff0088;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  font-weight: 700;
}

.addcart-btn svg {
  padding-left: 20px;
}

.wish-btn > button {
  height: 100%;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid rgb(216, 216, 216) !important;
  cursor: pointer;
  color: #ff0088;
  background-color: #f3f3f3;
}

.wish-btn > button:hover {
  background-color: #ff0088;
  color: #f3f3f3;
}

.product-cart:hover .image-link div .second-img {
  opacity: 1;
  visibility: visible;
  transform: scale(1.1);
  width: 100%;
}

.product-cart:hover .image-link div .frist-img {
  visibility: hidden;
}
.product-grid .image-link div .frist-img {
  width: 100%;
}
.product-grid .image-link div .one-image:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.product-cart:hover .icon-zoom {
  visibility: visible;
  transition: all 0.3s ease;
}

.tip {
  background-color: #ff0088;
  color: aqua;
}

.tooltip.show {
  opacity: 1;
}
.tooltip-inner {
  background-color: #ff0088 !important;
  color: white !important;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
}

.tooltip-arrow::before {
  border-top-color: #ff0088 !important;
}

.stats {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

@media (pointer: coarse) {
  .tooltip-inner {
    display: none;
  }

  .tooltip-arrow::before {
    display: none;
  }

  .frist-img {
    visibility: visible !important;
  }

  .second-img {
    visibility: hidden !important;
  }
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}
.product-grid .wish-btn,
.product-grid .step,
.product-grid .description,
.product-grid .price-tax,
.product-grid .stats {
  display: none;
}
.product-grid .caption {
  gap: 5px !important;
  height: 50%;
}
.product-grid .product-cart {
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  height: 500px;
}
.home .product-row .product-grid .product-cart {
  width: 75%;
}
.home .product-row .product-grid .product-cart .name {
  line-height: 1;
  word-spacing: 1px;
  word-break: break-word;
}
.product-grid .cart-btn,
.product-grid .addcart-btn {
  width: 100%;
  justify-content: center;
}
.product-grid .wrap-btn {
  padding: 20px 0;
}
.product-grid .card-product {
  padding: 10px;
}
.product-grid .addcart-btn {
  background-color: white;
  color: #ff0088;
  border: 1px solid #cfcfcf;
}
.product-grid .addcart-btn:hover {
  background-color: #ff0088;
  color: white;
  transition: all 0.3s ease;
}
.btn-filter {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff0088;
  z-index: 100;
  padding: 6px 11px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  margin-bottom: 60px;
}
.swpier-quick {
  width: 50%;
}
.swpier-quick-1,
.swpier-quick-2 {
  width: 100%;
}
.swpier-quick-1 {
  height: 70%;
}
.swpier-quick-1 .swiper,
.swpier-quick-1 img {
  height: 100%;
}
.swpier-quick-2 {
  margin-top: 10px;
}
.swpier-quick-2 .swiper-button-prev,
.swpier-quick-2 .swiper-button-next {
  color: black;
  background-color: transparent;
}
.swpier-quick-2 .swiper-wrapper {
  flex-direction: row;
}
.swpier-quick-2 .swiper-slide {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 2px;
}
.canvas-center + .offcanvas .wrap-btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  background: #e4e2e2;
  padding: 10px 20px;
  border-top: 1px solid #bcb7b7;
}
.addcart-btn,
.cart-btn {
  flex-grow: 1;
}
.canvas-center + .offcanvas .offcanvas-body > .container-swiper {
  width: 800px;
  gap: 10px;
  padding-top: 10px;
  margin-bottom: 40px;
}
.canvas-center + .offcanvas .offcanvas-body .content-of-info {
  display: none;
}
.discount {
  position: absolute;
  background-color: var(--main-bg-color);
  color: white;
  z-index: 20;
  right: 0;
  top: 10px;
  padding: 0px 20px;
  font-size: 13px;
}
.before-discount {
  color: #656565;
  text-decoration: line-through !important;
  margin-left: 7px;
}
.notification-add {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 109000;
  pointer-events: none;
}
