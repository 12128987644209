.content-body {
  display: flex;
  justify-content: start;
  padding: 40px 0;
}
.content-body > * {
}

.Product-body {
  padding: 10px 20px 10px 30px;
  flex-grow: 1;
}

.row-filter {
  width: 270px;
  padding: 10px 7px;
  border-right: 1px solid rgb(211, 211, 211);
  flex-shrink: 0;
}
@media (max-width: 860px) {
  .row-filter {
    width: 240px;
  }
}
@media (max-width: 761px) {
  .row-filter {
    display: none;
  }
  .Product-body {
    padding: 0;
  }
}
.clear {
  background-color: red;
  color: white;
  font-size: 13px;
  padding: 2px 4px;
  border-radius: 5px;
}
.row-filter-1 {
}
.colls-open {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
}
.colls-open a {
  font-size: 16px;
  font-weight: 700;
}
.wrap-filter {
  border-bottom: 1px solid rgb(189, 189, 189);
  padding-bottom: 20px;
}
.item-filter {
  display: flex;
  flex-direction: column;
}
.item-filter > * {
  padding: 1px 0;
  text-wrap: nowrap;
}
.item-filter input {
  margin-right: 5px;
}

.show-more {
  padding: 2px 4px;
  background-color: red;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  cursor: pointer;
}
.shadow-div {
  width: 100%;
  height: 35px;
  position: absolute;
  background-image: linear-gradient(180deg, #ffffff59 10%, #7b7b7b0f 60%);
  bottom: 0;
  left: 0;
}
.info-1 {
  height: 100px;
  overflow: hidden;
}
.show-div {
  height: auto;
}
.product-filter {
  padding: 20px 0;
  margin-bottom: 20px;
}
.tool-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
}
.sort-filter {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sort-filter .input-filter > * {
  border-radius: 3px;
  height: 26px;
  font-size: 12px;
  font-family: system-ui;
}
.sort-filter label {
  background-color: #e4e4e4;
  padding: 4px 10px;
}
.sort-filter .input-filter:first-child select {
  max-width: 160px;
  width: 160px;
  font-weight: normal;
}
.input-filter {
  display: flex;
  align-items: center;
}
.input-filter select {
  border: 1px solid #cfcfcf;
}

.grid-list button,
.grid-list button:hover {
  background-color: white !important;
  color: black !important;
}
.mobile-filter .row-filter {
  display: block !important;
  border: none !important;
  width: 100%;
}
.bar-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #3c3c3c;

  box-shadow: 0px -5px 10px 0px #908b8b3b;
  z-index: 999;
}
.bar-link > * {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bar-link .offer {
  color: #ff0099;
}
.breadcrumb > li {
  font-size: 12px;
  display: inline-block;
  margin-right: 10px;
}
.breadcrumb > li:not(:first-child)::before {
  content: "→";
  margin-right: 5px;
}
.range-slider .range-slider__range {
  background-color: var(--main-bg-color);
}
.range-slider .range-slider__thumb {
  background-color: var(--main-bg-color);
  width: 18px;
  height: 18px;
}
.range-slider {
  height: 4px;
}
.brand-filter {
  display: flex;
  width: 100%;

  flex-wrap: wrap;
  justify-content: space-between;
}
.brand-filter button:hover,
.brand-filter button:hover,
.brand-filter button {
  background-color: transparent !important;
  padding: 0;
  margin-bottom: 10px;
}

.brand-filter > * {
  width: 23%;
  height: auto;
  aspect-ratio: 1;
}
.brand-filter input {
  display: none;
}
.brand-filter img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  border: 1px solid #e4e4e4;
}
.brand-filter input:checked + img {
  transition: all 0s !important ;
  border: 1px solid #ff0022;
}
