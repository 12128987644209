.btn-admin > button {
  border: none;
  padding: 10px 15px;
  color: white;
  margin-top: 10px;
}
.label-in select,
.label-in textarea,
.label-in input {
  max-width: 250px;
  width: 250px;
}
label textarea {
  height: 200px;
  resize: none;
}
/* .btn-admin>button:hover {
  background-color: #ff0022 !important;
} */

.brand-data {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
@media (max-width: 681px) {
  .brand-data {
    justify-content: center;
  }
}

input[type="file"] {
  padding: 5px 0;
  border-radius: 15px;

  font-size: 14px;
}

input::file-selector-button {
  background-color: #ff0022;
  border: 1px solid rgb(117, 117, 117);
  border-radius: 15px;
  color: white;
  font-size: 14px;
}

.container-choose {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.container-img {
  width: fit-content;
  position: relative;
}

.container-img button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 50%;
  padding: 7px;
  aspect-ratio: 1 / 1;
  width: 13px;
  height: 18px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff0022;
  color: white;
}

.search-details {
  max-width: 75%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: auto;
  background-color: rgb(192, 192, 192);
  margin-bottom: 40px;
  margin-top: 20px;
}

.search-details input {
  max-width: 100%;
  width: 100%;
  padding: 4px 10px;
  border: 1px solid rgb(206, 206, 206);
}

.search-details div {
  padding: 0px 11px;
}

.table-admin table {
  width: 100%;
}

.table-admin table thead {
  border-bottom: 1px solid rgb(94, 94, 94);
}

.table-admin table thead td {
  background-color: rgb(224, 224, 224);
  padding: 10px 2px;
}

.table-admin table td {
  padding: 10px 5px;
}

.table-admin table td:last-child {
  text-align: center;
}

.table-admin img {
  max-width: 100px;
  width: 100%;
  aspect-ratio: 2/1;
  object-fit: cover;
}
.add-info input {
  margin-right: 5px;
}
.table-admin tr {
  border-bottom: 0.5px solid;
}
.btn-add {
  position: fixed;
  bottom: 22px;
  right: 63px;
  box-shadow: 0px -1px 5px 5px #127900b3;
}
@media  (pointer:coarse) { .btn-add-moblie{
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
}
}

 
.btn-admin button{
  width: 100%;
}
@media (max-width) {
  
}
.btn-add .btn-admin button {
  margin: 0 !important;
}
/* HTML: <div class="loader"></div> */
/* HTML: <div class="loader"></div> */
.filter-order {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px 30px;
  background: #0a120e12 !important;
  bottom: 15px;
  border-radius: 5px;
}

.filter-order button {
  margin-right: 10px;
  border: none;
  border-radius: 2.5px;
  background-color: #198754;
  color: white;
  font-weight: 300;
}
@media (max-width: 480px) {
  .filter-order {
    bottom: 75px;
    width: 100%;
  }
  .filter-order .filter-order-container {
    text-align: center;
    width: 100%;
  }
  .filter-order button {
    margin-bottom: 20px;
  }
}
.home-admin {
  margin-top: 20px;
}
.home-admin .grid-home {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 10px;
}
.home-admin .grid-home a {
  text-align: center;
  padding: 20px 30px;
  border: 0.5px solid transparent;
  border-radius: 3px;
}
.home-admin .grid-home a:hover {
  border-color: rgba(78, 78, 78, 0.836);
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.brand-data input,
.brand-data textarea,select {
  border: 1px solid black;
}
.table-admin {
  overflow-x: auto;
}
.table-admin::-webkit-scrollbar-thumb{
border: none;
  background-color: #ff0022;
 }
.table-admin::-webkit-scrollbar {
  height: 0;


  
}
.table-admin::-webkit-scrollbar-track{
 
  height: 10px;
}