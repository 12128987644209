@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Noto Serif", serif !important;
  overflow-x: hidden;
}

:root {
  --font-slg: 24px;
  --font-smid: 20px;
  --font-ssm: 14px;
  --main-bg-color: #ff0055;
}

* {
  list-style: none !important;
  text-decoration: none !important;
  box-sizing: border-box;
}

ul {
  padding: 0 !important;
}

h2 {
  font-size: var(--font-slg);
  font-weight: 600;
}

h3 {
  font-size: var(--font-smid);
  font-weight: 600;
}

h5 {
  font-size: var(--font-ssm);
  font-weight: 600;
}

.container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.input-search {
  min-width: 160px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border: 1px solid rgb(214, 214, 214);
  border-radius: 10px;
  margin: 0 20px;
}

.input-search input:focus .input-search {
  border: 1px solid black !important;
}

.input-search input {
  height: 100%;
  border: none;
  border-radius: 10px;
  flex-grow: 1;
  padding: 0 20px;
}

.input-search input:focus {
  outline: none !important;
}

.icon-search {
  padding: 10px 20px;
}

.cart-account {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
}

.cart-account span {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 300;
}

.cart-account svg {
  font-size: 18px;
}

.cart-account :last-child span:last-of-type:hover {
  color: var(--main-bg-color);
}

.link {
  padding: 10px 20px;

  position: relative;
  background-color: white;
  cursor: pointer;
  text-wrap: nowrap;
}

.link-item {
  position: absolute;
  top: 50%;
  left: 0;
  min-width: 175px;
  font-size: var(--font-ssm);

  padding: 0 !important;
  box-shadow: 1px 2px 5px -2px #2c2c2c;
  visibility: hidden;
  z-index: 20;
  background-color: white;
}

.link:hover .link-item {
  top: 100%;
  visibility: visible;
  transition: all 0.3s ease;
}

.link-item > *:hover {
  background-color: rgb(236, 236, 236);
  transition: all 0.3s ease;
}

.link span {
  font-weight: 700;
  font-size: var(--font-ssm);
}

.main-link {
  background-color: var(--main-bg-color);
  color: white;
  min-width: 200px;
}

.section-main {
  position: absolute;
  background-color: white;
  visibility: visible !important;
}

.link-main {
  position: absolute;
  visibility: hidden !important;
  z-index: 1;
  top: 0 !important;
  left: 100%;
  min-width: 175px;
  font-size: var(--font-ssm);

  padding: 0 !important;
  box-shadow: 1px 2px 5px -2px #2c2c2c;
  z-index: 20;
  background-color: white;
}

.link-main > *:hover {
  background-color: rgb(236, 236, 236);
  transition: all 0.3s ease;
}

.section-item {
  position: relative;
  display: block;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-weight: 400;
}

.item-main {
  position: absolute;
  z-index: 20;
  background-color: white;
  min-width: 250px;
}

.section-link {
  position: relative;
}

.section-main {
  visibility: visible !important;
}

.row-contact {
  background-color: #e5e5e5;
  padding: 20px;
}

.all-rows {
  margin-top: 40px;
}

.row-1,
.row-1 > .contact,
.row-1 > .contact > * {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
}

.row-1 {
  gap: 20px;
}

.row-1 > :first-child {
  flex-grow: 1;
}

.row-1 > .contact,
.row-1 > .contact > * {
  gap: 20px;
}

.row-1 > .contact > * svg {
  padding: 20px;
  background-color: #fdfdfd;
  border-radius: 50%;
}

.row-cate {
  background-color: rgba(22, 22, 22, 1);
  padding: 85px 0;
}

.product-row {
  padding: 60px 0;
}

.product-row-view {
  padding: 80px 0 60px 0;
}
.product-row-view img:hover {
  transform: scale(1.1);
  transition: all 0.3s ease;
}
.row-cate .row-2 {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row-cate span a {
  padding: 5px 0;
  color: #bab8ba !important;
}

.row-cate svg {
  font-size: 24px;
  margin-right: 10px;
}

.submit {
  flex-direction: column;
  gap: 20px;
}

.submit > * input,
.submit textarea {
  flex-grow: 1;
}

.submit > * label {
  width: 100px;
  color: white;
}

.submit > * {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.btn-submit {
  background-color: var(--main-bg-color);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: var(--main-bg-color);
  color: white;
  transition: all 0.3s ease;
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  color: white !important;
}

.swiper-button-prev,
.swiper-button-next {
  background: aliceblue;
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  color: black;
  font-size: 20px !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  display: none !important;
}

.swiper-pagination-bullet {
  width: 20px !important;
  border-radius: 5px !important;
  border: 1px;
  background-color: #ffffff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active {
  border: 1px solid #ffffff;
  background-color: #f50541 !important;
}

.home {
  display: flex;
  flex-direction: column;
}

.view-product::before {
  content: "";
  max-width: 90px;
  max-height: 90px;
  background-color: #bab8ba;
  position: absolute;
  z-index: -1;
  border-radius: 50%;
  width: 250px;
  height: 250px;
  left: 50%;
  transform: translate(-50%, -62%);
  top: 50%;
}

.mycart:hover .hover-cart {
  display: block;
  transform: translateY(0px);
  opacity: 1;
}

.icon-tel > * {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--main-bg-color);
}

.acount button {
  border: none !important;
}

.pos-fixed-bar {
  top: -50%;

  background-color: white;
}

.pos-fixed-bar-transition {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.3s ease;
  z-index: 999;
  background-color: white;
  padding-top: 0 !important;
  box-shadow: -7px -6px 20px 0px #00000047;
}

@media (max-width: 991px) {
  .header {
    display: none;
  }
}
