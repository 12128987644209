.canvas-center + .offcanvas {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  transition: none;
  width: fit-content !important;
  height: fit-content;
  border-radius: 3px;
}
.canvas-center + .offcanvas .offcanvas-body {
  padding: 10px;
}
.canvas-center + .offcanvas .offcanvas-header {
  width: fit-content;
  position: absolute;
  top: 13px;
  right: 30px;
  padding: 0;
  background-color: white;
  font-size: 11px;
}
.canvas-center + .offcanvas .offcanvas-header button:focus {
  box-shadow: none;
}
.canvas-center + .offcanvas .container-login {
  border: none;
  margin: auto;
  padding: 10px 0px;
  width: 500px !important;
}
