.colls-search #search-item {
  position: absolute;
  width: calc(100% - 2px);
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.5s ease;
  visibility: hidden;
}
.collapse.show {
  top: 100% !important;
  visibility: visible !important;

  transition: z-index 2s ease !important;
}
.colls-search #search-item input {
  width: 100%;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: 1px 1px 3px rgb(142, 142, 145);
}
.colls-search #search-item input:focus {
  outline: 1px groove rgb(43, 43, 43) !important;
}
.icon-bar {
  padding: 10px;
}
.icon-bar svg {
  font-size: 25px;
}

.offcanvas-header {
  background-color: #e2e2e2;
  padding: 10px 20px;
}
.log svg,
.regs svg {
  margin-right: 10px;
  font-size: 10px;
  padding: 4px;
  border-radius: 50%;
  border: 1px solid;
  margin-top: 1px;
}
.log svg,
.regs svg {
  font-size: 10px !important ;
}
.log,
.regs {
  display: flex;
  align-items: center;
}
.log svg {
  background-color: white !important;
  color: black !important;
}
.cart-conva tbody tr {
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
  
}
.canva-link + .offcanvas .offcanvas-body {
  padding: 0 !important;
}
.cart-conva tbody tr td img {
  max-width: 50px;
}
.cart-conva tbody tr .td-name {
  font-size: 14px;
}
.cart-conva tbody tr td.row-t-body {
  font-size: 13px;
  padding: 7px;
}
.cart-conva tbody tr .td-close {
  color: var(--main-bg-color);
}
.offcanvas-body .cart-conva .product tbody{
  display: block;
}
.offcanvas-body .cart-conva .product .row-t-body {
  flex-grow: 1;
  
}
.cart-conva .total-cart {
  position: sticky;
  right: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
  background-color: white;
}
.mob-head{
  display: none;
}
@media (max-width:991px) {
  .mob-head{
display: block;
  }
}
