.res-table table {
  border: 1px solid rgb(226, 226, 226);
}

.res-table thead {
  background-color: rgb(238, 238, 238);
}

.res-table tbody tr {
  border-top: 1px solid rgb(226, 226, 226);
}

.res-table table tr td {
  min-width: 90px;
}

.res-table table tr td:nth-of-type(2) {
  min-width: 175px;
}

.res-table tr img {
  max-width: 100%;
}

.row-t-head {
  padding: 12px !important;
}

.row-t-body {
  padding: 15px;
  vertical-align: middle;
}

.td-quantity .group-td-count {
  display: flex;
  gap: 5px;
}

.td-quantity button,
.td-quantity button:hover {
  background-color: #ff0088;
}

.hover-cart {
  min-width: 400px;
  width: 400px;

  position: absolute;

  z-index: 10;
  right: 0;

  display: none;
  transform: translateY(10px);
  opacity: 0;
  transition: opacity 5s ease display 1s ease;
}

.hover-cart-1::before {
  content: "";
  width: 0;
  border: 12px solid transparent;
  right: 10px;
  position: relative;
  display: block;
  left: 90%;
  border-bottom-color: #ffffff;
}

.hover-cart .row-t-body {
  padding: 0px;
}

.hover-cart .td-image {
  max-width: 120px;
}

.hover-cart .td-name {
  font-size: 14px;
  text-align: justify;
}

.hover-cart .td-close {
  font-size: 12px;
  color: red;
}

.hover-cart .td-image img {
  width: 100%;
}

.hover-cart tr {
  align-items: center;
  display: flex;
  gap: 20px;
}

.hover-cart ul {
  box-shadow: 3px -2px 12px 0px #d6d3d1b3;
  border-radius: 3px;
  background-color: white;
}

.hover-cart ul tr {
  padding: 10px !important;
  border-bottom: 1px solid #e1e1e1;
  justify-content: space-between;
}
.hover-cart ul tbody {
  display: block;
}

.hover-cart .product {
}

.hover-cart .product a {
  color: var(--main-bg-color);
  text-decoration: underline !important;
}

.btns-cart {
  margin: auto;
  width: fit-content;
}

.total-cart {
  padding-bottom: 10px;
}

.btns-cart button {
  width: fit-content;
  border: 1px solid #e0e0e048;
  border-radius: 2.5px;
  outline: none;
  padding: 5px 15px;
  color: var(--main-bg-color);
  font-weight: 600;
  font-size: 13px;
  background-color: white;
  margin-right: 20px;
}

.btns-cart .btn-view:hover,
.btns-cart .btn-check-out {
  background-color: var(--main-bg-color);
  color: white;
  transition: all 0.3s ease;
  border: 1px solid var(--main-bg-color);
  margin-top: 10px;
  margin-bottom: 10px;
}

.price-total {
  text-align: end;
  background-color: #e1e1e1;
  margin-bottom: 20px;
}

.price-total > * {
  padding: 10px;
}

.cart-bottom {
  width: 35%;
  padding: 20px;
  background-color: rgb(236, 236, 236);
  flex-grow: 1;
}

.cart-bottom div {
  color: black;
}

.container-cart {
  display: flex;
  gap: 20px;
  padding-bottom: 60px;
}

@media (max-width: 1012px) {
  .container-cart {
    flex-wrap: wrap;
  }

  .res-table table tr td:nth-of-type(2) {
  }

  .res-table {
    overflow-x: auto;
    border: 1px solid rgb(238, 238, 238);
  }

  .res-table::-webkit-scrollbar {
    height: 2px;
    width: 1px;
  }

  .res-table::-webkit-scrollbar-thumb {
    background-color: var(--main-bg-color);
  }
}

.input-cart-coupon button,
.input-cart-coupon input {
  padding: 5px 10px;
  margin: 0 !important;
  border: none;
  border-radius: 3px;
}
.input-cart-coupon input:focus {
  box-shadow: inset 0px 0px 4px 1px #7d7d7d85;
}
.input-cart-coupon button {
  background-color: var(--main-bg-color);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.cart-bottom label {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -1px;
}
.select-region {
  border: 1px solid rgb(220 220 220);
  border-radius: 3px;
  padding: 3px 7px;
  color: #7c6e6e;
}
.cart-bottom .price-total > *:nth-child(odd) {
  background-color: white;
}
.cart-bottom .price-total > *:nth-child(even) {
  background-color: #ececec;
}
