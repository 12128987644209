.btn-login button {
  width: 100%;
  border: 1px solid #ff0055;
  border-radius: 2px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ff0055;
  color: white;
}
.btn-register .btn-login button {
  background-color: white;
  border: 1px solid #d0d0d0;
  color: black;
}
.btn-register .btn-login button:hover {
  color: white;
  background-color: #ff0055;
  border-color: #ff0055;
  transition: all 0.3s;
}
.container-login {
  max-width: 500px;
  width: 100%;
  padding: 50px 30px;
  border: 1px solid #e2e4e5;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 7px;
}
.sign-in input {
  border: 1px solid #e2e4e5;
  padding: 5px 10px;
}
