@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  font-family: "Inter", sans-serif !important;
}
* {
  text-decoration: none !important;
}
a {
  color: #000;
}
:root {
  --font-slg: 24px;
  --font-smid: 20px;
  --font-ssm: 14px;
  --main-bg-color: #ff0055;
}
/* .loader {
  width: 15px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #999999;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
} */
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #f03355) content-box;
  -webkit-mask: repeating-conic-gradient(
      #0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg
    ),
    radial-gradient(
      farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b))
    );
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}
@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}

.massage-error {
  font-size: calc(0.8vw + 10px);
  margin: 30px 10px;
  text-align: center;
  padding: 10px 5px;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
 
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(219, 219, 219);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(95, 95, 95);
  border-radius: 5px;
  border: 3px solid rgb(219, 219, 219);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
@media (max-width: 480px) {
  .order-user {
    overflow-x: auto;
  }
  .order-user > div {
    width: 480px;
  }
}
@media (max-width: 991px) {
  .admin-user {
    overflow-x: auto;
  }
  .admin-user > div {
    width: 760px;
  }
}
.admin-user::-webkit-scrollbar-thumb{
  border: none;
    background-color: #ff0022;
   }
  .admin-user::-webkit-scrollbar {
    height: 0;
  
  
    
  }
  .admin-user::-webkit-scrollbar-track{
   
    height: 10px;
  }
  .order-user::-webkit-scrollbar-thumb{
    border: none;
      background-color: #ff0022;
     }
    .order-user::-webkit-scrollbar {
      height: 0;
    
    
      
    }
    .order-user::-webkit-scrollbar-track{
     
      height: 10px;
    }
/* common style */

/****************/
