.content-body-product {
  display: flex;
  justify-content: start;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swpier-one img {
  width: auto !important;
  height: 100% !important;
  aspect-ratio: 1/1 !important;
}
.swpier-one .swiper {
  height: 100%;
}
.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
}
.mySwiper > div {
  display: flex;
  flex-direction: column;
  width: fit-content;

  margin: 0 !important;
}
.mySwiper > div > div {
  border: 1px solid #dddddd;
  width: auto !important;
}
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 1;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.home .swiper-view img{
   object-fit: fill;
}
.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  aspect-ratio: 1 / 1;
}
.swpier-product {
  display: flex;
  height: 100%;
}
.swpier-one {
  max-height: 400px;
}
.swpier-two img {
  object-fit: initial;
}
.swpier-two {
  width: 100%;
  margin-left: 10px;
  border: 1px solid #dddddd;
  overflow: hidden;
  height: 100%;
}
.wrap-swiper {
  width: 50%;
  max-height: 600px;
}
.swiper-view .swiper {
  height: calc(100vh - 180px);
}
@media (max-width: 991px) {
  .wrap-swiper {
    width: 100%;
    aspect-ratio: 1/1;
  }
  .content-body-product {
    flex-wrap: wrap;
  }
  .swiper-view .swiper {
    height: auto;
    aspect-ratio: 2/1;
  }
  .swiper-view .swiper-pagination-bullet {
    height: 4px;
    width: 15px !important;
  }
}
.info-product {
  padding: 10px;
  width: 50%;
  flex-grow: 1;
}
.info-product .cart-btn,
.info-product .addcart-btn {
  flex-grow: 1;
}
.product-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.product-stats .brand a {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(204, 204, 204);
  padding: 5px;
  align-items: center;
}
.brand img{
  width: 100px;
  height: auto;
  aspect-ratio: 1;
  object-fit: contain;
}
.product-stats .brand a span {
  font-size: 12px;
  text-decoration: underline !important;
  color: var(--main-bg-color);
}
.product-stats ul .in-stock::before {
  content: "\2714	";
  margin-right: 5px;
  margin-left: 5px;
}
.product-stats ul .out-stock::before {
  content: "\2716	";
  margin-right: 5px;
  margin-left: 5px;
}
.info-product .title-product {
  font-weight: 700;
}
.product-stats ul li::before {
  content: "\25CF	";
  margin-right: 5px;
}
.product-stats .in-stock {
  color: rgb(61, 194, 61);
}
.product-stats .out-stock {
  color: rgb(255, 0, 0);
}
.content-of-info {
}
.info-block {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-top: 20px;
}
.info-block .info-text {
  font-size: 12px;
  color: #aaa5a5;
}
.info-block .info-title {
  font-size: 14px;
  font-weight: 800;
}
.icon-info {
  border: 1px solid;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.table-info table {
  width: 100%;
  border: 1px solid #d8d8d8;
}
.table-info table td {
  padding: 15px;
}
.table-info table thead td {
  background-color: #aaa5a5;
  width: 100%;
}
.table-info table tbody tr:nth-child(odd) {
  background-color: #fff;
}
.table-info table tbody tr:nth-child(even) {
  background-color: #d8d8d8;
}
.table-info table tbody tr td:first-child {
  width: 25%;
}
.tab-relation {
  border: none !important;
}
.tab-relation .nav-link:hover {
  border: none !important;
}
.nav-tabs .nav-link {
  border: none !important;
  color: black !important;
}
.tab-relation .nav-link.active {
  border: none;
  border-bottom: 1px solid var(--main-bg-color) !important;
}
.tab-relation .pro-cart {
  padding: 0 !important;
}
.pro-cart .wrap-btn {
  display: none;
}
.pro-cart .product-cart {
  border: 1px solid rgb(221 221 221);
  border-radius: 4px;
}
